
import newAxios from '../../config/request'

// 定义登录方法
export const loginFun = function(params={}){
    return newAxios.post("/api/v1/login",params,{
        isPublic:true
    })
}

// 登录用户的菜单权限
export const selectLeftMenu = function(){
    return newAxios.get("/api/v1/menus")
}
