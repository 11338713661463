import "element-ui/lib/theme-chalk/index.css"
import "./assets/css/mian.css"

import Vue from 'vue'
import ElementUI from "element-ui"

import router from './router'

import App from './App.vue'
import store from './store'
import mixin from "./mixin";

Vue.config.productionTip = false

Vue.use( ElementUI )

// 为所有vue的组件都定义了 混合配置
Vue.mixin( mixin )

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
