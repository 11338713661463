import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userRight:[]
  },

  getters: {
  },

  mutations: {
    setUserRight(state,nv){
      state.userRight = nv;
    }
  },

  actions: {
  },
  
  modules: {
  }
})
