// 为axios提供拦截器定义
import axios from 'axios'
import { Message } from 'element-ui';

const newAxios = axios.create({
    // baseURL:"http://127.0.0.1:3000"
    // 读取环境文件 = 会自动加载到 process.env
    baseURL:process.env.VUE_APP_API_BASE_URL
});

newAxios.interceptors.request.use(
    function(config){
        
        if(!config.isPublic){
            config.headers.Authorization = "Bearer " + localStorage.getItem("token");
        }

        return config;
    },
    function(error){
        return Promise.reject(error);
    }
)

newAxios.interceptors.response.use(
    function( response ){
        let { data } = response;

        // 判断了服务器的响应数据结果是否正确
        if(data.status>=400){
            Message.error(data.msg)
            return Promise.reject(response);
        }

        return data.data;
    },
    function(error){
        Message.error("请求失败，稍后重试")
        return Promise.reject(error);
    }
)

export default newAxios;