<template>
  <router-view></router-view>
</template>

<script>
export default {
  created(){
    console.log( process.env.VUE_APP_API_BASE_URL );
  }
}
</script>